import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Arrow } from 'img/btn-arrow.svg'
import Header from 'components/Header'
import {
  $isInstalled,
  $status,
  checkMetamask,
  loginMetamaskFx,
  setStatus,
  signMessageFx,
  Status,
} from 'store/app/metamask'
import discordIcon from 'img/discord.svg'
import metamaskImg from 'img/metamsk.svg'
import successImg from 'img/success.svg'
import congratsIcon from 'img/congrats.svg'
import errorImg from 'img/error.svg'
import {
  $address,
  $isAuth,
  checkAuth,
  fetchSignatureMessageFx,
  setAddress,
  verifyUser,
} from 'store/app'
import { useSearchParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import { DISCORD_GUILD_INVITE, OAUTH_DISCORD_URL } from 'configs/app.config'

function Form() {
  const [searchParams] = useSearchParams()
  const isAuth = useStore($isAuth)
  const isInstalled = useStore($isInstalled)
  const status = useStore($status)
  const address = useStore($address)

  useEffect(() => {
    if (!isAuth) {
      const code = searchParams.get('code')
      checkAuth(code)
    }
    checkMetamask()
  }, [])

  const authMetamsk = async () => {
    const [message, address] = await Promise.all([
      fetchSignatureMessageFx(),
      loginMetamaskFx(),
    ])

    if (!message) {
      return
    }

    const sign = await signMessageFx({ address, message })
    await verifyUser({ address, message, sign })
  }

  return (
    <Root>
      <Steps>
        <Step isActive={!isAuth} isDone={isAuth ?? undefined}>
          Discord
        </Step>
        <Step
          isActive={isAuth ?? undefined}
          isDone={
            ((isAuth ?? undefined) && status === Status.Verified) ||
            status === Status.NoTokens
          }
        >
          Metamask
        </Step>
        <Step isDone={status === Status.Verified}>Success</Step>
      </Steps>
      {!isAuth && (
        <Auth>
          <DiscordIcon />
          <BigText>Welcome to the Occam DAO verification system!</BigText>
          <SmallText>
            Connect your Discord profile to your Ethereum address to verify your
            tier and NFT and start participating in Occam DAO.
          </SmallText>
          <Button href={OAUTH_DISCORD_URL}>
            Link your Discord account
            <BtnArrow>
              <Arrow />
            </BtnArrow>
          </Button>
        </Auth>
      )}
      {isAuth && !status && (
        <Auth>
          <BigText>Discord connected successfully</BigText>
          <Header isSuccess={false} />
          {isInstalled && !status && (
            <MetamaskBtn onClick={authMetamsk}>
              <MetamaskIcon />
              Connect Metamask
              <BtnArrow>
                <Arrow />
              </BtnArrow>
            </MetamaskBtn>
          )}
          {status && <span>{status}</span>}
          {!isInstalled && (
            <MetamaskLink href="#" target={'_blank'}>
              <MetamaskIcon />
              Download metamask
              <BtnArrow>
                <Arrow />
              </BtnArrow>
            </MetamaskLink>
          )}
          <ErrorIcon />
          <Error>Metamask connection error, please try again.</Error>
          {status === Status.NoTokens && <span></span>}
        </Auth>
      )}
      {status === Status.NoTokens && (
        <Auth>
          <SuccessIcon />
          <BigText>
            No Occam <span>DAO NFT found</span>
          </BigText>
          <Address>
            Looks like address
            <span>{address}</span>
            doesn't hold an Occam NFT.
          </Address>
          <VerySmallText>
            You can connect another address or proceed with this one. You'll
            become verified for the Occam DAO once this address get the NFT.
          </VerySmallText>
          <NoNFTLink>How to obtain my Occam DAO NFT</NoNFTLink>
          <ChangeAddress
            onClick={() => {
              setStatus(null)
              setAddress('')
            }}
          >
            Change address
          </ChangeAddress>
          <SuccessBtn
            href="https://razer.occam.fi/personal/occ-panel/staking"
            target="_blank"
          >
            Go Stake
            <BtnArrow>
              <Arrow />
            </BtnArrow>
          </SuccessBtn>
        </Auth>
      )}
      {isAuth && status === Status.Verified && (
        <Auth>
          <CongratsIcon />
          <CongratsText>Congratulations!</CongratsText>
          <VerySmallText>
            Your Discord profile is now connected to your Ethereum address.
            Proceed to discord to start participating in Occam DAO.
          </VerySmallText>
          <Header isSuccess={true} />
          <SuccessBtn href={DISCORD_GUILD_INVITE}>
            Occam DAO Discord
            <BtnArrow>
              <Arrow />
            </BtnArrow>
          </SuccessBtn>
        </Auth>
      )}
    </Root>
  )
}

export default Form

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0 40px 49px rgba(111, 117, 133, 0.2);
  border-radius: 20px;
  z-index: 3;
`

const Auth = styled.div`
  position: relative;
  padding: 45px;
`

const Steps = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 42px;
`

const Step = styled.div<{ isActive?: boolean; isDone?: boolean }>`
  position: relative;
  font-family: 'NeueHaasDisplay';
  width: 33.33%;
  height: 100%;
  padding-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 98%;
  text-transform: uppercase;
  color: rgba(37, 37, 37, 0.3);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #252525;
    opacity: 0.1;
  }

  ${({ isActive }) => {
    if (isActive)
      return css`
        color: rgba(37, 37, 37, 0.8);

        &:before {
          opacity: 0.5;
        }
      `
  }}
  ${({ isDone }) => {
    if (isDone)
      return css`
        color: #5865f2;

        &:before {
          background: #5865f2;
          opacity: 1;
        }
      `
  }}
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  background: #5865f2;
  box-shadow: 0px 40px 49px rgba(142, 146, 194, 0.2);
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  font-family: 'NeueHaasDisplay';
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
`

const BtnArrow = styled.div`
  position: absolute;
  top: 15px;
  right: 22px;
  width: 12px;
  height: 12px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const DiscordIcon = styled.div`
  position: relative;
  width: 45px;
  height: 35px;
  margin: 0 auto 30px;
  background: url('${discordIcon}') center no-repeat;
  background-size: cover;
`

const BigText = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 200;
  font-size: 34px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 16px;
  color: #252525;

  span {
    display: block;
  }
`

const SmallText = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  opacity: 0.5;
  margin-bottom: 32px;
  color: #252525;
`

const MetaMaskCss = css`
  position: relative;
  display: block;
  padding-left: 20px;
  width: 316px;
  height: 55px;
  background: #ff8f2b;
  box-shadow: 0px 40px 49px rgba(181, 146, 116, 0.2);
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  font-family: 'NeueHaasDisplay';
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 auto;
`

const MetamaskBtn = styled.button`
  ${MetaMaskCss};
`

const MetamaskIcon = styled.div`
  position: absolute;
  width: 28px;
  height: 25px;
  top: 15px;
  left: 25px;
  background: url('${metamaskImg}') center no-repeat;
  background-size: cover;
`

const MetamaskLink = styled.a`
  ${MetaMaskCss};
  display: flex;
  justify-content: center;
  align-items: center;
`

const SuccessIcon = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 25px;
  background: url('${successImg}') center no-repeat;
  background-size: cover;
`

const VerySmallTextCss = css`
  font-family: 'NeueHaasDisplay';
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  margin-bottom: 16px;
`

const Address = styled.div`
  ${VerySmallTextCss};
  color: #252525;

  span {
    display: block;
    font-weight: 500;
    font-size: 12px;
  }
`

const VerySmallText = styled.div`
  ${VerySmallTextCss};
  color: #252525;
  opacity: 0.5;
`

const SuccessBtn = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  width: 293px;
  height: 55px;
  background: #5865f2;
  box-shadow: 0px 40px 49px rgba(142, 146, 194, 0.2);
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  font-family: 'NeueHaasDisplay';
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 auto;
`

const NoNFTLink = styled.a`
  ${VerySmallTextCss};
  display: block;
  color: #5865f2;
  cursor: pointer;
  text-decoration-line: underline;
`

const ChangeAddress = styled.a`
  ${VerySmallTextCss};
  display: block;
  color: #252525;
  opacity: 0.5;
  cursor: pointer;
  text-decoration-line: underline;
`

const CongratsIcon = styled(SuccessIcon)`
  background: url('${congratsIcon}') center no-repeat;
  background-size: cover;
`

const CongratsText = styled(BigText)`
  margin-bottom: 10px;
`

const ErrorIcon = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  width: 16px;
  height: 16px;
  background: url('${errorImg}') center no-repeat;
  background-size: cover;
`

const Error = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #252525;
  opacity: 0.8;
`
