import {
  attach,
  createEvent,
  createStore,
  restore,
  createEffect,
} from 'effector'
import Cookies from 'js-cookie'
import { setStatus, Status } from './metamask'
import { API_URL } from 'configs/app.config'
import { checkBasicAuth } from 'utils/checkBasicAuth'

interface DiscordUser {
  avatar: string
  id: string
  username: string
  discriminator: string
}

export const setSession = createEvent<string>()

export const $session = restore(setSession, '')

export const $isAuth = createStore<boolean | null>(null)
export const setIsAuth = createEvent<boolean>()

export const $user = createStore<DiscordUser | null>(null)
export const setUser = createEvent<DiscordUser | null>()

export const $address = createStore<string>('')
export const setAddress = createEvent<string>()

const authFx = createEffect(async ({ code }: { code: string }) => {
  const url = `${API_URL}?code=${code}`
  const req = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: checkBasicAuth(),
    },
  })
  const resp = await req.json()
  if (resp.error) {
    throw Error(resp.error)
  }
  return resp
})

authFx.done.watch(({ result }) => {
  if (result.error) return
  const { oauthData, user } = result
  Cookies.set('atd', oauthData.access_token, {
    expires: oauthData.expires_in,
  })
  setUser(user)
  setSession(oauthData.access_token)
  if (user.verificated) setStatus(Status.Verified)
  setIsAuth(true)
})

authFx.fail.watch(() => {
  setIsAuth(false)
})

const fetchUserFx = createEffect(
  async ({ accessToken }: { accessToken: string }) => {
    const url = `${API_URL}/me`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        accessToken,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: checkBasicAuth(),
      },
    })
    return req.json()
  }
)

fetchUserFx.done.watch(({ result }) => {
  if (result.error) {
    Cookies.remove('atd')
    setSession('')
    setIsAuth(false)
    setUser(null)
    setStatus(null)
    return
  }
  setUser(result)
  setIsAuth(true)
  console.log(result)
  if (result.verificated) setStatus(Status.Verified)
})

const fetchRevokeFx = createEffect(
  async ({ accessToken }: { accessToken: string }) => {
    const url = `${API_URL}/revoke`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        accessToken,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: checkBasicAuth(),
      },
    })
    return req.json()
  }
)

fetchRevokeFx.done.watch(({ result }) => {
  Cookies.remove('atd')
  setSession('')
  setIsAuth(false)
  setUser(null)
})

export const fetchRevoke = attach({
  source: $session,
  async effect(session) {
    if (!session) return
    await fetchRevokeFx({ accessToken: session })
  },
})

// export const fetchContractFx = createEffect(
//   async ({ address }: { address: string }) => {
//     const url = `http://localhost:3001/abi`
//     const req = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify({
//         address,
//       }),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     return req.json()
//   }
// )

// fetchContractFx.done.watch(({ result }) => {
//   console.log(result)
// })

export const checkAuth = attach({
  source: $session,
  async effect(session, code) {
    if (!session) {
      const accessToken = Cookies.get('atd')
      console.log('cookie ', accessToken)
      if (accessToken) {
        setSession(accessToken)
        await fetchUserFx({ accessToken })
      } else {
        console.log('no session')
        if (!code) return setIsAuth(false)
        await authFx({ code })
      }
    }
  },
})

export const fetchSignatureMessageFx = attach({
  source: $session,
  async effect(session) {
    if (!session) {
      return
    }

    const url = `${API_URL}/signature-challenge`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ accessToken: session }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: checkBasicAuth(),
      },
    })

    return req.text()
  },
})

export type VerifyUserParams = {
  address: string
  message: string
  sign: string
}

export const verifyUser = attach({
  source: $session,
  //   @ts-ignore
  async effect(session, { address, message, sign }: VerifyUserParams) {
    console.log({ session, address })
    if (!session || !address) return

    const url = `${API_URL}/verify`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        accessToken: session,
        address,
        message,
        sign
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: checkBasicAuth(),
      },
    })
    const json: any = await req.json()
    console.log(json)
    const isNoAmount = json.error_key === 'no_amount'
    if (isNoAmount) {
      setStatus(Status.NoTokens)
    }
    if (json.message === 'OK') {
      setStatus(Status.Verified)
    }
    return json
  },
})

// verifyUser.pending.watch(() => {
//   setStatus(Status.CheckingRole)
// })

// verifyUser.done.watch(() => {
//   console.log('verifying done')
//   setStatus(Status.Verified)
// })

// verifyUser.failData.watch((err) => {
//   // @ts-ignore
//   const isNoAmount = err.error_key === 'no_amount'
//   console.log(isNoAmount)
// })
