import {
  $address,
  $isAuth,
  $session,
  $user,
  setAddress,
  setIsAuth,
  setSession,
  setUser,
} from '.'

import {
  $isInstalled,
  $status,
  loginMetamaskFx,
  setIsInstalled,
  setStatus,
} from './metamask'

import Cookies from 'js-cookie'
import { sample } from 'effector'

if (Cookies.get('atd') === 'undefined') {
  Cookies.remove('atd')
}

$session.on(setSession, (_, session) => session)
$user.on(setUser, (_, user) => user)
$isAuth.on(setIsAuth, (_, isAuth) => isAuth)
$isInstalled.on(setIsInstalled, (_, isInstalled) => isInstalled)
$status.on(setStatus, (_, status) => status)
$address.on(setAddress, (_, address) => address)

sample({
  source: loginMetamaskFx.doneData,
  target: setAddress,
})
