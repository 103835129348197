import React from 'react'
import styled from 'styled-components'
import mountainsImg from 'img/mountains.png'
import ellipsesImg from 'img/ellipses.svg'
import cloudsImg from 'img/clouds.png'
import logoImg from 'img/logo.svg'
import headsetImg from 'img/headset.svg'
import Form from 'components/Form'

{
  /* <>
<Header />
{isAuth === false && (
  <Auth>
    <a href={process.env.REACT_APP_DISCORD_OAUTH}>Auth to discord</a>
  </Auth>
)}
{isAuth && (
  <Auth>
    {isInstalled && !status && (
      <button type="button" onClick={authMetamsk}>
        Login to Metamask
      </button>
    )}
    {status && <span>{status}</span>}
    {!isInstalled && (
      <a href="#" target={'_blank'}>
        Download metamask
      </a>
    )}
    {status === Status.NoTokens && <span></span>}
  </Auth>
)}
</> */
}

function Index() {
  return (
    <Root>
      <Mountains src={mountainsImg} />
      <Clouds />
      <Container>
        <Ellipses />
        <Logo />
        <Form />
        <Row>
          <Headset />
          <Support>Contact Support</Support>
        </Row>
      </Container>
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #ffffff 0%, #ebebeb 100%);
  overflow: hidden;
`

const Mountains = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
`

const Container = styled.div`
  position: relative;
  width: 446px;
  margin: 100px auto 0;
`

const Ellipses = styled.div`
  position: absolute;
  top: -538px;
  left: 50%;
  transform: translateX(-50%);
  width: 1861px;
  height: 1894px;
  background: url('${ellipsesImg}') center no-repeat;
  background-size: contain;
`

const Clouds = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url('${cloudsImg}') center no-repeat;
  background-size: cover;
  z-index: 2;
`

const Logo = styled.div`
  position: relative;
  width: 160px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 45px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  z-index: 3;
`

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  z-index: 3;
`

const Headset = styled.div`
  width: 26px;
  height: 24px;
  margin-right: 10px;
  background: url('${headsetImg}') center no-repeat;
  background-size: cover;
`

const Support = styled.div`
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  opacity: 0.8;
  color: #252525;
`
