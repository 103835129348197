import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    scroll-behavior: smooth;
    height: 100%;
    min-height: 100%;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    background: rgba(0,0,0, .8);
    color: #fff;
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }

  input {
    background: none;
    border-radius: 8px;
    outline: none;
  }

  textarea {
    outline: none;
  }

  a {
    text-decoration: none;
    color: cornflowerblue;
  }
  p {
    margin: 0;
  }
  tr, th, td, table {
  }
`

export const Container = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.main.xl} {
    max-width: 1300px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    max-width: 1125px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    max-width: 970px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    max-width: 820px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    max-width: 560px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    max-width: 340px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    max-width: 280px;
  }
`

export const TitleH2 = styled.h2`
  font-weight: 500;
  font-size: 50px;
  letter-spacing: -2px;
  line-height: 48px;
  color: #000000;
  margin: 0;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 46px;
    line-height: 42px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 42px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 36px;
    line-height: 30px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 36px;
    line-height: 31px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 32px;
    line-height: 31px;
  }
  span {
    color: #647bf7;
  }
`

export default GlobalStyle
