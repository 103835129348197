import { createGlobalStyle } from 'styled-components'
import NeueHaasDisplayWoff2Medium from './fonts/NeueHaasDisplay-Medium.woff2'
import NeueHaasDisplayWoffMedium from './fonts/NeueHaasDisplay-Medium.woff'
import NeueHaasDisplayWoff2Regular from './fonts/NeueHaasDisplay-Roman.woff2'
import NeueHaasDisplayWoffRegular from './fonts/NeueHaasDisplay-Roman.woff'
import NeueHaasDisplayWoff2Thin from './fonts/NeueHaasDisplay-Thin.woff2'
import NeueHaasDisplayWoffThin from './fonts/NeueHaasDisplay-Thin.woff'
import NeueHaasDisplayWoff2Light from './fonts/NeueHaasDisplay-Light.woff2'
import NeueHaasDisplayWoffLight from './fonts/NeueHaasDisplay-Light.woff'

// Neue Haas Display Display Pro
export const neueHassDisplay = {
  thin: {
    woff2: NeueHaasDisplayWoff2Thin,
    woff: NeueHaasDisplayWoffThin,
  },
  light: {
    woff2: NeueHaasDisplayWoff2Light,
    woff: NeueHaasDisplayWoffLight,
  },
  medium: {
    woff2: NeueHaasDisplayWoff2Medium,
    woff: NeueHaasDisplayWoffMedium,
  },
  regular: {
    woff2: NeueHaasDisplayWoff2Regular,
    woff: NeueHaasDisplayWoffRegular,
  },
}

// const Aeroport = {
//   regular: {
//     woff2: AeroportWoff2,
//     woff: AeroportWoff,
//   },
//   bold: {
//     woff2: AeroportBoldWoff2,
//     woff: AeroportBoldWoff,
//     italic: {
//       woff2: AeroportBoldItalicWoff2,
//       woff: AeroportBoldItalicWoff,
//     },
//   },
//   medium: {
//     woff2: AeroportMediumWoff2,
//     woff: AeroportMediumWoff,
//   },
//   light: {
//     woff2: AeroportLightWoff2,
//     woff: AeroportLightWoff,
//     italic: {
//       woff2: AeroportLightItalicWoff2,
//       woff: AeroportLightItalicWoff,
//     },
//   },
// }

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.light.woff2}) format('woff2'),
    url(${neueHassDisplay.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.thin.woff2}) format('woff2'),
    url(${neueHassDisplay.thin.woff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
         url(${neueHassDisplay.medium.woff2}) format('woff2'),
         url(${neueHassDisplay.medium.woff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.regular.woff2}) format('woff2'),
    url(${neueHassDisplay.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
