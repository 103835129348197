import { createEffect, createEvent, createStore } from 'effector'
import { ETH_NETWORK_CHAIN_ID } from 'configs/app.config'

export enum Status {
  MetmaskConnecting = 'Connecting to Metamask',
  CheckingNetwork = 'CheckingNetwork',
  CheckingTokens = 'Checking your NFTs',
  CheckingRole = 'Choosing role for you',
  Verified = 'Congratulations you have succefully verfifed!',
  NoTokens = 'You have no staked amount on your address',
}

export const $address = createStore('')
export const $status = createStore<Status | null>(null)
export const $isInstalled = createStore(true)

export const setIsInstalled = createEvent()
export const setStatus = createEvent<Status | null>()

export const checkMetamask = () => {
  setIsInstalled(window.ethereum!!)
}

export const switchMetamaskNetoworkFx = createEffect(async () => {
  return window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: ETH_NETWORK_CHAIN_ID }],
  })
})

export const loginMetamaskFx = createEffect(async (): Promise<string> => {
  await switchMetamaskNetoworkFx()
  const [address] = await window.ethereum.request({
    method: 'eth_requestAccounts',
  })
  return address
})

loginMetamaskFx.pending.watch(() => setStatus(Status.MetmaskConnecting))

switchMetamaskNetoworkFx.pending.watch(() => setStatus(Status.CheckingNetwork))

switchMetamaskNetoworkFx.done.watch(({ result }) => {
  console.log('swith network result: ', result)
})

export type SignMessageParams = {
  address: string
  message: string
}

/**
 * @see {@link https://docs.metamask.io/guide/signing-data.html#personal-sign}
 */
export const signMessageFx = createEffect(
  async ({ address, message }: SignMessageParams): Promise<string> => {
    const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`
    return window.ethereum.request({
      method: 'personal_sign',
      from: address,
      params: [msg, address],
    })
  }
)
