import { BASIC_AUTH_LOGIN, BASIC_AUTH_PASS } from 'configs/app.config'
import { Base64 } from 'js-base64'

export const checkBasicAuth = (): string => {
  let auth = ''
  if (BASIC_AUTH_LOGIN && BASIC_AUTH_PASS) {
    const authString = `${BASIC_AUTH_LOGIN}:${BASIC_AUTH_PASS}`
    auth = `Basic ${Base64.encode(authString)}`
  }
  return auth
}
