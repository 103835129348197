import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { $address, $user, fetchRevoke } from 'store/app'

interface Props {
  isSuccess: boolean
}

function Header(props: Props) {
  const user = useStore($user)
  const address = useStore($address)

  const logut = () => {
    fetchRevoke()
  }

  useEffect(() => {
    console.log(user)
  }, [user])

  if (!user) return null

  const userAvatar = user?.avatar
    ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/${
        Number(user.discriminator) % 5
      }.png`

  return (
    <Root>
      {user && (
        <User>
          <Avatar src={userAvatar} />
          <Name>
            {user?.username}
            <span>#{user?.discriminator}</span>
          </Name>
          {props.isSuccess && <Address>{address}</Address>}
          {!props.isSuccess && (
            <Button onClick={logut}>Logout of Discord</Button>
          )}
        </User>
      )}
    </Root>
  )
}

export default Header

const Root = styled.div`
  display: flex;
`

const User = styled.div`
  position: relative;
  width: 100%;
`

const Name = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 200;
  font-size: 24px;
  line-height: 110%;
  text-align: center;
  margin-top: 8px;
  color: #252525;
  span {
    font-weight: 500;
  }
`

const Avatar = styled.img`
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 8px;
  object-fit: cover;
`

const Button = styled.button`
  position: relative;
  font-family: 'NeueHaasDisplay';
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  text-decoration-line: underline;
  opacity: 0.5;
  margin: 20px auto;
  cursor: pointer;
`

const Address = styled.div`
  position: relative;
  font-family: 'NeueHaasDisplay';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
  color: #252525;
`
