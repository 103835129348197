import GlobalStyle from './theme/globalStyle'
import GlobalFonts from './theme/fonts'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Index from './components/Index'

function App() {
  return (
    <Router>
      <GlobalStyle />
      <GlobalFonts />
      <Routes>
        <Route index element={<Index />} />
      </Routes>
    </Router>
  )
}

export default App
